import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import { MenSlider, WomenSlider, KidsSlider } from "../../Data";
import { useEffect, useState } from "react";


function ProductPageSlider({ from }) {

    const [Images, setImages] = useState([])

    useEffect(() => {
        switch (from) {
            case 'Men':
                setImages(MenSlider)
                break;
            case 'Women':
                setImages(WomenSlider)
                break;
            case 'Kids':
                setImages(KidsSlider)
                break;

            default:
                break;
        }

    }, [from])



    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <button onClick={onClick} className="btn next-arrow">
                <FaAngleRight />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <button onClick={onClick} className="btn prev-arrow">
                <FaAngleLeft />
            </button>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        lazyLoad: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <>

            <div className='container-fluid mb-3 mt-4'>
                <div className='row px-xl-5'>
                    <div className='col mb-30'>
                        <Slider {...settings}>

                            {Images.map((img,idx) => {
                                return (
                                    <div key={idx}>

                                        <div className="carousel-item position-relative active" style={{ height: 500 }}>
                                            <img
                                                className="w-100 h-100"
                                                src={img}
                                                style={{ objectFit: "cover" }}
                                                alt=""
                                            />

                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </div>


        </>
    );

}

export default ProductPageSlider;

