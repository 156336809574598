import React from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'


function MegaMenu({ mainCat }) {
    return (
        <>

            <div className="dropdown-menu w-100 mt-0 bg-white show" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>

                <div className="container">
                    <div className="row my-4" >

                        {

                            mainCat.all_subcategories.map(subcat => {
                                // .......TOP.........
                                return (
                                    <div key={subcat.id} className="col-3 border-right" >
                                        <h6 className='mb-2'>{subcat.name}</h6>
                                        <div className="row mb-4">

                                            {
                                                subcat.all_subcategories.map(lastcat => {
                                                    // .......last layer items ..........//
                                                    return (
                                                        <div key={lastcat.id} className="col-3 sub-nav-card">
                                                            <Link to={`/products-list/${lastcat.id}`} className="cat-name">
                                                            <img className='sub-nav-img' src={lastcat.image} alt="" />
                                                                {lastcat.name}
                                                                </Link>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default MegaMenu
