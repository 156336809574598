
import QestionPage from './Pages/auth/Questions/QuestionPage';
import {Cart, EditProfile, Favourite, Home, OrdersList, ProductDetails, ProductsPage, ProductsSearch, ShopPage} from './Pages/index'

const routes = [
    { path: '/', name: 'Home', exact: true },
    { path: '/home', name: 'Home', element: Home },
    { path: '/edit-profile', name: 'EditProfile', element: EditProfile },
    { path: '/cart', name: 'Cart', element: Cart },
    { path: '/favourite', name: 'fav', element: Favourite },
    { path: '/shop/:name', name: 'ShopPage', element: ShopPage },
    { path: '/products-list/:id', name: 'ProductsPage', element: ProductsPage },
    { path: '/product-detail/:id', name: 'ProductDetails', element: ProductDetails },
    { path: '/Products-search/:suggestion', name: 'ProductDetails', element: ProductsSearch },
    { path: '/order-list', name: 'OrdersList', element: OrdersList },
    { path: '/questions', name: 'questions', element: QestionPage },

];


export default routes
