import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
// import { Cart, Checkout, EditProfile, Home, OrdersList, ProductDetails, ProductsPage } from '../Pages'
import Footer from './Footer'
// import Test from '../Pages/Test'
import Navbar from './Navbar/Navbar'
import routes from '../routes'


function Master() {

  return (
    <>
      <Navbar />
      <Routes>

        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          )
        })}
        <Route path="/" element={<Navigate to="home" replace />} />

        {/* <Route exact path='/' element={<Home />} />
        <Route path='edit-profile' element={<EditProfile />} />
        <Route path='men' element={<Test p={'mens'} />} />
        <Route path='women' element={<Test p={'women'} />} />
        <Route path='kids' element={<Test p={'kids'} />} />
        <Route path='cart' element={<Cart />} />
        <Route path='checkout' element={<Checkout />} />
        <Route path='product-detail' element={<ProductDetails />} />
        <Route path='products-list' element={<ProductsPage />} />
        <Route path='order-list' element={<OrdersList />} /> */}

      </Routes>
      <Footer />

    </>
  )
}

export default Master
