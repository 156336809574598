
function SectionTitle(props) {
    return (
        <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4">
            <span className="bg-ofwhite pr-3">{props.children}</span>
        </h2>
    )
}
function SectionTitleSm(props) {
    return (
        <h5 className="section-title position-relative text-uppercase mb-4">
            <span className="bg-ofwhite pr-3">{props.children}</span>
        </h5>
    )
}

export default SectionTitle
export {SectionTitleSm}
