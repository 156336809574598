import { createSlice } from '@reduxjs/toolkit'

const storeInLocal = (product) => {
    localStorage.setItem('favourite', JSON.stringify(product))
}
const featchFormLocal = () => {
    const favList = localStorage.getItem('favourite')
    if (favList) {
        return JSON.parse(localStorage.getItem('favourite'))
    }
    return []
}

const initialState = {
    favItems: featchFormLocal()
}

const favSlice = createSlice({
    name: 'favourite',
    initialState,
    reducers: {
        addToFav(state, action) {
            const newItem = action.payload;
            const existItem = state.favItems.find((item) => item.id === newItem.id)
            if (!existItem) {
                state.favItems.push(newItem)
                storeInLocal(state.favItems)
            }

        },

        deleteFromFav(state, action) {
            state.favItems = state.favItems.filter((item) => item.id !== action.payload.id)
            storeInLocal(state.favItems)
        }
    }
});

export const { addToFav, deleteFromFav } = favSlice.actions

export default favSlice.reducer