import React, { useEffect, useState } from 'react'
// import Question from './Question'
import '../auth.css'
import axios from 'axios';
import { BASEURL } from '../../../utils/apiURL';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../Components/index';
import { FaArrowRight } from 'react-icons/fa';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';


function QuestionPage() {

    const [sizes, setSizes] = useState([])
    const [colors, setColors] = useState([])
    const [style, setStyle] = useState()
    const [category, setCategory] = useState([])
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedColors, setSelectedColors] = useState([])
    const [selectedSize, setSelectedSize] = useState([]);
    const [kindWear, setKindWear] = useState([]);
    const [kindOccasion, setKindOccasion] = useState([]);
    const { user } = useSelector(state => state.auth)
    const navigate=useNavigate()

    useEffect(() => {
        axios.get(`${BASEURL}all/colors/sizes`)
            .then(res => {
                setSizes(res.data.data.sizes);
                setColors(res.data.data.colors);
            })
    }, [])

    useEffect(() => {
        axios.post(`${BASEURL}getInformationQuestion`, {
            token: user.token
        })
            .then(res => {
                if(res.data.code===200){
                    setStyle(res.data.data.style)
                    setCategory(res.data.data.categories)
                }else{
                    swal('Error',res.data.errors.error,"error")
                    // console.log(res.data.errors.error)
                }
            })
    }, [user])

    const hendelPreviousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1)
        }
    }

    const hendelNextStep = () => {
        if (currentStep < 4) {
            setCurrentStep(currentStep + 1)
        }
    }
    

    const QuestionOne = () => {

        const handelKinkWare = (kind) => {
            if (kindWear.includes(kind)) {
                const exists = kindWear.filter((k) => k !== kind);
                setKindWear(exists);
            } else {
                setKindWear([...kindWear, kind]);
            }
        }

        return (
            <form>
                <div className="form-group step-form">
                    <p>What Kind of clothes do you usually  ware</p>
                    <div className="container">
                        <div className="row">
                            {category?.map((cat, idx) => {
                              const isChecked = kindWear.includes(cat.name)
                                return (
                                    <div key={idx} className="col-3 custom-control custom-checkbox mb-3">
                                        <input type="checkbox" className="custom-control-input" id={`cat${cat.id}`}
                                           checked={isChecked}
                                           value={cat.name}
                                            onChange={() => { handelKinkWare(cat.name) }}
                                        />
                                        <label className="custom-control-label" htmlFor={`cat${cat.id}`}>{cat.name}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const QuestionTwo = () => {
        
        const handelOccasionsWare = (occasions) => {
            if (kindOccasion.includes(occasions)) {
                setKindOccasion(kindOccasion.filter((oc) => oc !== occasions));
            } else {
                setKindOccasion([...kindOccasion, occasions]);
            }
        }

        return (

            <div className="form-group step-form">
                    <p> What kind of occasions do you frequent</p>
                    <div className="container">
                        <div className="row">
                            {style?.map((style, idx) => {
                                const isChecked = kindOccasion.includes(style)
                                return (
                                    <div key={idx} className="col-3 custom-control custom-checkbox mb-3">
                                        <input type="checkbox" className="custom-control-input" id={`style${style}`}
                                            checked={isChecked}
                                            value={style}
                                            onChange={() => { handelOccasionsWare(style) }}
                                        />
                                        <label className="custom-control-label" htmlFor={`style${style}`}>{style}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

        );
    };

    const QuestionThree = () => {

        const handelSelectColors = (color_id) => {
            if (selectedColors.includes(color_id)) {
                setSelectedColors(selectedColors.filter((color) => color !== color_id));
            } else {
                setSelectedColors([...selectedColors, color_id]);
            }
        }
        return (
            <form>
                <div className="form-group step-form">
                    <p>What is your favorite colors </p>
                    <div className="container">
                        <div className="row">
                            {colors?.map((color, idx) => {
                                const isChecked = selectedColors.includes(color.id)
                                return (
                                    <div key={idx} className="col-3 custom-control custom-checkbox mb-3">
                                        <input type="checkbox" className="custom-control-input" id={`color${color.id}`}
                                            checked={isChecked}
                                            value={color.name}
                                            onChange={() => { handelSelectColors(color.id) }}
                                        />
                                        <label className="custom-control-label" htmlFor={`color${color.id}`}>{color.name}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const QuestionFour = () => {

        const handelSelectSize = (size_id) => {
            if (selectedSize.includes(size_id)) {
                const exists = selectedSize.filter((size) => size !== size_id);
                setSelectedSize(exists);
            } else {
                setSelectedSize([...selectedSize, size_id]);
            }
        }
        return (
            <form>
                <div className="form-group step-form">
                    <p>What sizes are you wearing</p>
                    <div className="container">
                        <div className="row">
                            {sizes?.map((size, idx) => {
                                const isChecked = selectedSize.includes(size.id)

                                return (
                                    <div key={idx} className="col-3 custom-control custom-checkbox mb-3">

                                        <input type="checkbox" className="custom-control-input" id={`size${size.id}`}
                                            checked={isChecked}
                                            value={size.size}
                                            onChange={() => handelSelectSize(size.id)}
                                        />
                                        <label className="custom-control-label" htmlFor={`size${size.id}`}>{size.size}</label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </form>
        );
    };


    const handelSubmit = () => {

        axios.post(`${BASEURL}storeUserPreferences`, {
            token: user.token,
            colors: selectedColors,
            sizes: selectedSize,
            events: kindOccasion,
            clothing_types: kindWear,
        })
            .then(res => {
                if (res.data.code === 200) {
                    console.log(res.data)
                    // redirect('/home')
                }
            })
            navigate('/home')
    }


    const forms = [QuestionOne, QuestionTwo, QuestionThree, QuestionFour,];

    const CurrentForm = forms[currentStep - 1];

    return (
        <div className='container'>
            {console.log(kindOccasion)}
            {console.log(kindWear)}
            <div className='row justify-content-center '>
                <div className='col-8'>
                    <div className='card mt-4'>
                        <CurrentForm />

                        <div className='d-flex mt-4 mb-2 ml-2'>
                            <div className='mr-2' onClick={() => hendelPreviousStep()}>
                                <PrimaryButton >Previous</PrimaryButton>
                            </div>
                            {currentStep !== 4 ?
                                <div className='mr-2' onClick={() => hendelNextStep()}>
                                    <PrimaryButton >Next</PrimaryButton>
                                </div>
                                :
                                <div className='ml-2 mb-2' onClick={() => handelSubmit()}>
                                    <PrimaryButton > Submit </PrimaryButton>
                                </div>

                            }
                        </div>

                    </div>
                    <div className='d-flex justify-content-end mt-2'>
                        <PrimaryButton href='/home'>Skip <FaArrowRight /> </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default QuestionPage
