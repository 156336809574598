

import men1 from "./asset/img/men-slider-1.jpg";
import men2 from "./asset/img/men-slider-2.jpg";
import men3 from "./asset/img/men-slider-3.jpg";

import kids1 from "./asset/img/kids-slider-1.jpg";
import kids2 from "./asset/img/kids-slider-2.jpg";
import kids3 from "./asset/img/kid-slider-3.jpg";

import women1 from "./asset/img/women-slider-1.jpg";
import women2 from "./asset/img/women-slider-2.jpg";
import women3 from "./asset/img/women-slider-3.jpg";


const images = [
    { id: 188, image: 'https://img.ltwebstatic.com/images3_pi/2022/10/20/16662002832e2e586a32499bf5b77e5be263154147_thumbnail_900x.webp', created_at: '2023-07-22T21:45:08.000000Z', },
    { id: 189, image: 'https://img.ltwebstatic.com/images3_pi/2022/05/11/16522389227e60ecf38bb5220d40079ebdd41745d4_thumbnail_600x.jpg', created_at: '2023-07-22T21:45:08.000000Z', },
    { id: 188, image: "https://img.ltwebstatic.com/images3_pi/2022/11/08/16678851620d27011135069c2803f682c1392e5d8b_thumbnail_600x.jpg", },
]
const sizes = [
    { id: 4, size: 'M', group_id: '1', created_at: '2023-07-03T06:22:46.000000Z', updated_at: '2023-07-03T06:22:46.000000Z' },
    { id: 5, size: 'L', group_id: '1', created_at: '2023-07-03T06:22:46.000000Z', updated_at: '2023-07-03T06:22:46.000000Z' },
    { id: 6, size: 'XL', group_id: '1', created_at: '2023-07-03T06:22:46.000000Z', updated_at: '2023-07-03T06:22:46.000000Z' },
]

const productData = [
    { id: '1', name: 'Product One', images: images, price: '128' ,sizes:sizes},
    { id: '2', name: 'Product Two', images: images, price: '200' ,sizes:sizes},
    { id: '3', name: 'Product Three', images: images, price: '300' ,sizes:sizes},
    { id: '4', name: 'Product Four', images: images, price: '50' ,sizes:sizes},
    { id: '5', name: 'Product Five', images: images, price: '150' ,sizes:sizes},
]

const description = "Color:\tDark Wash\nPattern Type:\tPlain\nType:\tFlare Leg\nClosure Type:\tZipper Fly\nDetails:\tButton, Pocket, Zipper\nWaist Line:\tDrop Waist\nLength:\tExtra Long\nFit Type:\tRegular Fit\nFabric:\tHigh Stretch";

const fullproductData = [
    { id: '1', name: 'SHEIN EZwear Bleach Wash Flare Leg Jeans', images: images, price: '60000', description: description, review: 4.5 ,sizes:sizes},
    { id: '2', name: 'Product Two', images: images, price: '200', description: description, review: 4.5 ,sizes:sizes},
    { id: '3', name: 'Product Three', images: images, price: '300', description: description, review: 4.5 ,sizes:sizes},
    { id: '4', name: 'Product Four', images: images, price: '50', description: description, review: 4.5 ,sizes:sizes},
    { id: '5', name: 'Product Five', images: images, price: '150', description: description, review: 4.5 ,sizes:sizes},
    { id: '6', name: 'Product six', images: images, price: '150', description: description, review: 4.5 ,sizes:sizes},
    { id: '7', name: 'Product seven', images: images, price: '300', description: description, review: 4.5 ,sizes:sizes},
    { id: '8', name: 'Product eight', images: images, price: '150', description: description, review: 4.5 ,sizes:sizes},
]



const singleProduct = { id: '1', name: 'Product One', images: images, price: '128', descriptionription: description }

const orders = [
    { id: '1sadsa', order_date: '5/4/2023', delever_date: '18/4/2023', status: 'pending', total: '$128', payment: 'credit card' },
    { id: '2sadsa', order_date: '6/12/2023 ', delever_date: '20/12/2023', status: 'complate', total: '$200', payment: 'credit card' },
    { id: 'xzcxzc3', order_date: '9/3/2022 ', delever_date: '19/3/2022', status: 'canceled', total: '$300', payment: 'credit card' },
    { id: '4xzcsa', order_date: '16/7/2022 ', delever_date: '20/7/2022', status: 'pending', total: '$50', payment: 'credit card' },
    { id: 'sasdw5', order_date: '25/2/2022 ', delever_date: '10/3/2022', status: 'complate', total: '$150', payment: 'credit card' },
]

const MenSlider = [men1, men2, men3]
const WomenSlider = [women1, women2, women3]
const KidsSlider = [kids1, kids2,kids3]
// const MenSlider = [carousel_1, carousel_2, carousel_3]
// const MenSlider = [carousel_1, carousel_2, carousel_3]

export default productData
export { fullproductData, singleProduct, orders, MenSlider, WomenSlider, KidsSlider } 