import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./component.css";
import Slider from "react-slick";
import { ProductCard } from './index'
import { FaAngleLeft, FaAngleRight, } from "react-icons/fa";


function ProductSlider({ data }) {

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <button onClick={onClick} className="btn next-arrow">
                <FaAngleRight />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <button onClick={onClick} className="btn prev-arrow">
                <FaAngleLeft />
            </button>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <>

            <Slider {...settings}>
                {/* {console.log(data)} */}
                {data.map((product, idx) => {
                    return (
                        <div key={idx}>
                            <ProductCard data={product} />
                        </div>
                    )
                })}
            </Slider>
        </>
    );


}

export default ProductSlider
