import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import Slider from "react-slick";
import carousel_1 from "./../../asset/img/men-slider.jpg";
import carousel_2 from "./../../asset/img/slider.jpg";
import carousel_3 from "./../../asset/img/kid-slider.jpg";

import { OutLineButton } from '../index'
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";


function Carousel() {

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <button onClick={onClick} className="btn next-arrow">
                <FaAngleRight />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <button onClick={onClick} className="btn prev-arrow">
                <FaAngleLeft />
            </button>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        autoplay: true,
        lazyLoad: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <>

            <div className='container-fluid mb-3 mt-4'>
                <div className='row px-xl-5'>
                    <div className='col mb-30'>
                        <Slider {...settings}>
                            <div className="carousel-item position-relative active" style={{ height: 430 }}>
                                <img
                                    className="w-100 "
                                    src={carousel_1}
                                    style={{ objectFit: "cover", height: "500px" }}
                                    alt=""
                                />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: 700 }}>
                                        <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">Mens Fashion</h1>
                                        <p className="mx-md-5 px-5 animate__animated animate__bounceIn">
                                            Men's fashion encompasses a collection of clothing and accessories designed to meet the needs and tastes of men
                                        </p>
                                        <OutLineButton href="/shop/Men">Shop Now</OutLineButton>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item position-relative" style={{ height: 430 }}>
                                <img
                                    className="w-100"
                                    src={carousel_2}
                                    style={{ objectFit: "cover", height: "500px" }}
                                    alt=""
                                />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: 700 }}>
                                        <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">Women Fashion</h1>
                                        <p className="mx-md-5 px-5 animate__animated animate__bounceIn">
                                            Women's fashion encompasses a diverse range of clothing and accessories designed to complement and express the individual styles and personalities of women
                                        </p>
                                        <OutLineButton href="/shop/Women">Shop Now</OutLineButton>

                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item position-relative" style={{ height: 430 }}>
                                <img
                                    className=" w-100 "
                                    src={carousel_3}
                                    style={{ objectFit: "cover", height: "500px" }}
                                    alt=""
                                />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: 700 }}>
                                        <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">Kids Fashion</h1>
                                        <p className="mx-md-5 px-5 animate__animated animate__bounceIn">
                                            Children's fashion focuses on clothing and accessories specially crafted for infants, toddlers, and young kids. It combines comfort, functionality, and aesthetics to meet the needs of growing children.
                                        </p>
                                        <OutLineButton href="/shop/Kids">Shop Now</OutLineButton>

                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>





            {/* <div className='container-fluid mb-3'>
                <div className='row px-xl-5'>
                    <div className='col-lg-8 mb-30'>
                        <Slider {...settings}>
                            <div className="carousel-item position-relative active" style={{ height: 430 }}>
                                <img
                                    // className="position-absolute w-100 h-100"
                                    src={carousel_1}
                                    style={{ objectFit: "cover" }}
                                    alt=""
                                />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: 700 }}>
                                        <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">Mens Fashion</h1>
                                        <p className="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                        <OutLineButton href="/">Shop Now</OutLineButton>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item position-relative" style={{ height: 430 }}>
                                <img
                                    // className="position-absolute w-100 h-100"
                                    src={carousel_2}
                                    style={{ objectFit: "cover" }}
                                    alt=""
                                />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: 700 }}>
                                        <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">Women Fashion</h1>
                                        <p className="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                        <OutLineButton href="/">Shop Now</OutLineButton>

                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item position-relative" style={{ height: 430 }}>
                                <img
                                    // className="position-absolute w-100 h-100"
                                    src={carousel_3}
                                    style={{ objectFit: "cover" }}
                                    alt=""
                                />
                                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                    <div className="p-3" style={{ maxWidth: 700 }}>
                                        <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">Kids Fashion</h1>
                                        <p className="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                        <OutLineButton href="/">Shop Now</OutLineButton>

                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="col-lg-4">
                        <div className="product-offer mb-30" style={{ height: 200 }}>
                            <img className="img-fluid" src={offer_1} alt="" />
                            <div className="offer-text">
                                <h6 className="text-white text-uppercase">Save 20%</h6>
                                <h3 className="text-white mb-3">Special Offer</h3>
                                <PrimaryButton href="/">
                                    Shop Now
                                </PrimaryButton>
                            </div>
                        </div>
                        <div className="product-offer mb-30" style={{ height: 200 }}>
                            <img className="img-fluid" src={offer_2} alt="" />
                            <div className="offer-text">
                                <h6 className="text-white text-uppercase">Save 20%</h6>
                                <h3 className="text-white mb-3">Special Offer</h3>
                                <PrimaryButton href="/">
                                    Shop Now
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );

}

export default Carousel;

