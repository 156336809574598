import React from 'react'
import { FaBuilding, FaUpload } from "react-icons/fa"
import './auth.css'
import '../pages.css'
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { companyRegister } from "../../store/auth";
import swal from 'sweetalert';

function RegisterCompany() {


    const nameRef = useRef();
    const emailRef = useRef();
    const descRef = useRef();
    const proDescRef = useRef();
    const est_dateRef = useRef();
    const regionRef = useRef();
    const countryRef = useRef();
    const phoneRef = useRef();
    const addressRef = useRef();
    const dispatch = useDispatch();
    const { loading, error, success_msg } = useSelector(state => state.auth)



    const registerHandler = async (e) => {
        e.preventDefault();
        let companyData = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            desciption: descRef.current.value,
            address: addressRef.current.value,
            phone: phoneRef.current.value,
            country: countryRef.current.value,
            region: regionRef.current.value,
            product_description: proDescRef.current.value,
            establishment_date: est_dateRef.current.value,
        }
        dispatch(companyRegister(companyData));

        if (success_msg) {
            swal("Success", success_msg, 'success');
        }

    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="registration-form form">
                        <form onSubmit={registerHandler}>
                            <div className="form-icon">
                                <span><FaBuilding /></span>
                            </div>

                            <div className='row'>

                                <div className='col-6'>
                                    <div className="form-group">
                                        <input type="text" className="form-control item" id="name" placeholder="Company Name" ref={nameRef} />
                                        <span className='text-danger mt-0'>{error?.name}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control item" id="phone-number" placeholder="Company Email" ref={emailRef} />
                                        <span className='text-danger mt-0'>{error?.email}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control item" id="phone" placeholder="Company Phone" ref={phoneRef} />
                                        <span className='text-danger mt-0'>{error?.phone}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control item" id="address" placeholder="Company Address" ref={addressRef} />
                                        <span className='text-danger mt-0'>{error?.address}</span>
                                    </div>
                                    <div >
                                        <label className="mx-3"> Upload Logo</label>
                                        <div class="btn btn-primary btn-rounded">
                                            <FaUpload className='mr-2' />
                                            <label class="form-label text-white m-1" for="customFile2">Choose file</label>
                                            <input type="file" class="form-control d-none" id="customFile2" />
                                        </div>
                                        {/* <PrimaryButton>   
                                            <span>Upload</span>
                                        </PrimaryButton> */}

                                    </div>


                                </div>

                                <div className='col-6'>
                                    <div className="form-group">
                                        <input type="text" className="form-control item" id="desc" placeholder="About Your Company" ref={descRef} />
                                        <span className='text-danger mt-0'>{error?.desciption}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control item" id="pro_desc" placeholder="Your Services" ref={proDescRef} />
                                        <span className='text-danger mt-0'>{error?.product_description}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type='date' className="form-control item" placeholder="Establishment Date" ref={est_dateRef} />
                                        {/* <DatePicker onChange={onDateChange}
                                            value={dateValue}
                                            maxDate={new Date()}
                                            format='yy-mm-dd'
                                             /> */}

                                        <span className='text-danger mt-0'>{error?.establishment_date}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control item" id="country" placeholder='Company country' ref={countryRef} />
                                        <span className='text-danger mt-0'>{error?.country}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control item" id="country" placeholder='Company region' ref={regionRef} />
                                        <span className='text-danger mt-0'>{error?.region}</span>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <button type="submite" className={`btn btn-block create-account ${loading ? 'disabled' : ''}`}>{loading ? 'Create Account...' : 'Create Account'}</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterCompany
