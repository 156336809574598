import { FaMinus, FaPlus, FaTimes } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { CancelButton, LargtButton, PayButton, PlusButton, PrimaryButton } from "../Components"
import { cartActions } from "../store/cartSlice"
import { useEffect } from "react"
import { formatPrice } from "../utils/helper"
import { Link } from "react-router-dom"
import empty_cart from "../asset/img/cart2.png"

function Cart() {

  const { cartItems, totalAmount } = useSelector(state => state.cart)
  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(cartActions.getCartTotal())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSelector(state => state.cart)])

  const emptyCart = () => {
    return (
      <>
        <div className="container-fluid mt-100">
          <div className="row">
            <div className="col-md-12">
              <div className="card">

                <div className="card-body ">
                  <div className="col-sm-12 empty-cart-cls text-center">
                    <img src={empty_cart} width="200" className="img-fluid mr-3" alt="" />
                    <h3><strong>Your Cart is Empty</strong></h3>
                    <h4>Add something to make me happy :)</h4>
                    <Link to="/" className="btn btn-primary cart-btn-transform m-3" data-abc="true">continue shopping</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>

      {cartItems.length === 0 ? emptyCart() : (
        <>
          <div className="container-fluid">
            <div className="row px-xl-5">
              <div className="col-12">
                <nav className="breadcrumb bg-white mb-30 justify-content-between">
                  <div className="d-flex">
                    <a className="breadcrumb-item text-dark" href="/">Home</a>
                    <span className="breadcrumb-item active">Shopping Cart</span>
                  </div>
                  <div onClick={() => { dispatch(cartActions.clearCart()) }}>
                    <PrimaryButton>
                      Clear Cart
                    </PrimaryButton>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row px-xl-5">
              {/* Cart Products */}
              <div className="col-lg-9 table-responsive mb-5">

                {cartItems.map((item, idx) => {
                  return (
                    <div key={idx} className="card cart-shadow rounded-3 mb-4">
                      {console.log(item)}
                      {console.log(item.size)}
                      <div className="card-body p-4">
                        <div className="row d-flex justify-content-between align-items-center">
                          <div className="col-md-2 col-lg-2 col-xl-2">
                            <img src={item.image} alt="" className="img-fluid rounded-3" />
                          </div>
                          <div className="col-md-3 col-lg-3 col-xl-3">
                            <p className="lead fw-normal mb-2">{item.name}</p>
                            <p><span className="text-muted">Size: </span>{item.size.size} </p>
                          </div>
                          <div className="col-md-2 col-lg-2 col-xl-2 d-flex">
                            <div onClick={() => dispatch(cartActions.decreaseFromCart(item))}>
                              <PlusButton>
                                <FaMinus />
                              </PlusButton>
                            </div>
                            <span className="m-auto">{item.qty}</span>
                            <div onClick={() => dispatch(cartActions.increaseInCart(item))}>
                              <PlusButton>
                                <FaPlus />
                              </PlusButton>
                            </div>
                          </div>
                          <div className="col-md-2 col-lg-2 col-xl-1" style={{ padding: 0, textAlign: 'center' }}>
                            <h5 className="mb-0">{formatPrice(item.price)}</h5>
                          </div>
                          <div className="col-md-2 col-lg-1 col-xl-1" style={{ padding: 0, textAlign: 'center' }}>
                            <h5 className="mb-0">{formatPrice(item.totalPrice)}</h5>
                          </div>
                          <div className="col-md-1 col-lg-2 col-xl-1 text-end">
                            <div onClick={() => { dispatch(cartActions.deleteFromCart(item)) }}>
                              <CancelButton><FaTimes /></CancelButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

              </div>
              {/* Cart Details */}
              <div className="col-lg-3">

                <div className="bg-white p-30 mb-5">
                  <div className="border-bottom pb-2">

                    <div className="border-bottom mb-3">
                      <h4>Cart Summary</h4>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <h6>Items</h6>
                      <h6>{cartItems.length}</h6>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                      <h6>Subtotal</h6>
                      <h6>{totalAmount}</h6>
                    </div>

                  </div>
                  <div className="pt-2">
                    <div className="d-flex justify-content-between mt-2">
                      <h5>Total</h5>
                      <h5>{totalAmount}</h5>
                    </div>

                    {
                      user ? (
                        // <LargtButton href='/checkout'>
                        //   Check Out
                        // </LargtButton>
                        <PayButton cartItems={cartItems} />
                        
                      ) :
                        (
                            <LargtButton href='/login'>
                              Login
                            </LargtButton>
                        )
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

      )
      }

    </>
  )
}

export default Cart
