import { createSlice } from '@reduxjs/toolkit'

const fetchFromLocalStorage = () => {
  let cart = localStorage.getItem('cart');
  if (cart) {
    return JSON.parse(localStorage.getItem('cart'))
  } else {
    return [];
  }
}
const storeInLocalStorage = (data) => {
  localStorage.setItem('cart', JSON.stringify(data))
}

const initialState = {
  cartItems: fetchFromLocalStorage(),
  totalAmount: 0,
}


const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const newItem = action.payload
      console.log(newItem);
      const existItem = state.cartItems.find((item) =>
        item.id === newItem.id &&
        item.color_id === newItem.color_id &&
        item.size.id === newItem.size.id
      )
      if (existItem) {
        existItem.qty = action.payload.qty;
        existItem.totalPrice = existItem.qty * existItem.price;
        storeInLocalStorage(state.cartItems)
      }

      else {

        state.cartItems.push({
          id: newItem.id,
          name: newItem.name,
          image: newItem.image,
          color_id: newItem.color_id,
          price: newItem.price,
          qty: newItem.qty,
          size: newItem.size,
          totalPrice: newItem.qty * newItem.price,
        });
        console.log(newItem);
        storeInLocalStorage(state.cartItems)
      }
    },

    increaseInCart(state, action) {
      const product = action.payload
      const exactItem = state.cartItems.find((item) =>
        item.id === product.id &&
        item.image.id === product.image.id &&
        item.size.id === product.size.id
      )
      exactItem.qty += 1;
      exactItem.totalPrice = exactItem.qty * exactItem.price;
      storeInLocalStorage(state.cartItems)
    },


    decreaseFromCart(state, action) {
      const newItem = action.payload
      const exactItem = state.cartItems.find((item) =>
        item.id === newItem.id &&
        item.image.id === newItem.image.id &&
        item.size.id === newItem.size.id
      )
      if (exactItem.qty === 1) {
        state.cartItems.filter((item) => item.id !== action.payload)
        storeInLocalStorage(state.cartItems)
      } else {
        exactItem.qty -= 1;
        exactItem.totalPrice = exactItem.qty * exactItem.price;
        storeInLocalStorage(state.cartItems)
      }
    },

    deleteFromCart(state, action) {
      // const newCart = state.cartItems.filter((item) => 
      // item.id !== action.payload.id && 
      // item.image.id !== action.payload.image.id&&
      // item.size.id !== action.payload.size.id
      // )
      // state.cartItems = newCart;
      // storeInLocalStorage(newCart)

      const { size, image } = action.payload;

      state.cartItems = state.cartItems.filter(
        (item) => !(item.size.id === size.id && item.image === image)
      );
      storeInLocalStorage(state.cartItems)
    },

    clearCart(state) {
      state.cartItems = [];
      storeInLocalStorage(state.cartItems);
    },

    getCartTotal(state) {
      state.totalAmount = state.cartItems.reduce((cartTotal, cartItem) => {
        return cartTotal += cartItem.totalPrice;
      }, 0);
      state.totalItems = state.cartItems.length;
    }
  }
});

export const cartActions = cartSlice.actions

export default cartSlice.reducer