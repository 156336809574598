import { Link, useParams } from "react-router-dom"
import { FaMinus, FaPlus, FaRegStar, FaShoppingCart } from "react-icons/fa"
import { ImageSlider, Loader, MinusButton, PlusButton, PrimaryButton, ProductSlider, SectionTitle, StarsRate } from "../../Components/index"
import { cartActions } from "../../store/cartSlice"

import pdata from './../../Data'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { formatPrice } from "../../utils/helper"
import { getProductDetails } from "../../store/productSlice"
// import { BASEURL } from "../utils/apiURL"
// import axios from "axios"
import NotFound from "../Error/NotFound"


function ProductDetails() {

  // const [details, setDetails] = useState(null)
  // const [notfound, setNotfound] = useState(false)
  const [qty, setQty] = useState(1)
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pro_Details, loading } = useSelector(state => state.products)
  useEffect(() => {
    dispatch(getProductDetails(id))
  }, [dispatch, id]);



  // const switch_image = (e) => {

  //   const c = e.target.getAttribute('data-color');
  //   const elements1 = document.getElementsByClassName('slick-active');
  //   if (elements1.length > 0) {
  //     const targetElement = elements1[0]; // Assuming you want to work with the first element with the class name

  //     // Go up two levels in the DOM tree using parentElement() and add the class 'slick-active'
  //     targetElement.classList.remove('slick-active');
  //     targetElement.classList.remove('slick-current');
  //     targetElement.setAttribute('aria-hidden', 'true');
  //   }
  //   const elements = document.getElementsByClassName('div_' + c);
  //   if (elements.length > 0) {
  //     const targetElement = elements[0]; // Assuming you want to work with the first element with the class name

  //     // Go up two levels in the DOM tree using parentElement() and add the class 'slick-active'
  //     targetElement.parentElement.parentElement.classList.add('slick-active');
  //     targetElement.parentElement.parentElement.classList.add('slick-current');
  //     targetElement.parentElement.parentElement.setAttribute('aria-hidden', 'false');
  //   }
  //   console.log(c);
  // }

  // const [selectedColorIndex, setSelectedColorIndex] = useState(1);

  // const switchImage = (colorIndex) => {
  //   setSelectedColorIndex(colorIndex);
  // };

  const increaseQty = () => {
    setQty((prevQty) => {
      return prevQty += 1;
    })
  }
  const decreaseQty = () => {
    setQty((prevQty) => {
      var newQty = prevQty - 1;
      if (newQty < 1) {
        newQty = 1;
      }
      return newQty;
    })
  }

  const [selectedImage, setSelectedImage] = useState({ color_id: pro_Details?.images[0].color_id, image: pro_Details?.images[0].image });
  const [selectedSize, setSelectedSize] = useState({ id: pro_Details?.sizes[0].id, size: pro_Details?.sizes[0].size });

  const switchImageHandler = (image) => {
    setSelectedImage(image);
  };

  const addToCartHandler = (product) => {

    const tempPro = { ...product, qty: qty, image: selectedImage.image, size: selectedSize, color_id: selectedImage.color_id}
    dispatch(cartActions.addToCart(tempPro))
  }

  if (loading) {
    return <Loader />
  }


  return (
    <>

      {/* Breadcrumb Start */}
      <div className="container-fluid">
        <div className="row px-xl-5">
          <div className="col-12">
            <nav className="breadcrumb bg-ofwhite mb-30">
              <Link className="breadcrumb-item text-dark" to="/">Home</Link>
              <Link className="breadcrumb-item text-dark" to="/">Shop</Link>
              <span className="breadcrumb-item active">Shop Detail</span>
            </nav>
          </div>
        </div>
      </div>
      {/* Breadcrumb End */}
      {pro_Details ? (
        <>
          {/* Shop Detail Start */}

          <div className="container-fluid pb-5">
            <div className="row px-xl-5">
              <div className="col-lg-5 mb-30">
                <div id="product-carousel" className="carousel slide" data-ride="carousel">
                  <ImageSlider images={pro_Details.images} />
                  {/* <ImageSlider images={pro_Details.images} selectedColorIndex={selectedColorIndex} /> */}
                </div>
              </div>
              <div className="col-lg-7 h-auto mb-30">
                <div className="h-100 bg-white p-30">
                  <h3>{pro_Details.name}</h3>
                  <div className="d-flex mb-3">

                    <StarsRate stars={pro_Details.review} />
                    <small className="pt-1">(99 Reviews)</small>
                  </div>
                  <h3 className="font-weight-semi-bold mb-4">{formatPrice(pro_Details.price)}</h3>
                  <p className="mb-4">{pro_Details.description}</p>

                  <div className="d-flex mb-3">
                    <strong className="text-dark mr-3">Sizes:</strong>

                    {pro_Details.sizes.map((size,idx) => {
                      const isChecked = selectedSize && selectedSize.id === size.id;
                      return (

                        <div key={idx} className="custom-control custom-radio custom-control-inline">
                          <input type="radio" checked={isChecked} className='custom-control-input' id={size.id} name="size"
                           onChange={() => setSelectedSize({ id: size.id, size: size.size })} />
                          <label className="custom-control-label" htmlFor={size.id}>{size.size}</label>
                        </div>
                      )
                    })}

                  </div>

                  <div className="d-flex mb-4 align-items-center">
                    <strong className="text-dark mr-3">Colors:</strong>

                    {pro_Details.images.map((image, index) => {
                      
                      const isActive = selectedImage.color_id === image.color_id;

                      return (
                        <div key={image.id} className="mr-3" >
                          {/* <input type="radio" className="custom-control-input" id={`color-${index}`} name="color" /> */}
                          {/* <img src={image.image} width={50} alt=""  data-color={image.color_id} onClick={()=>{this.getAttribute('data-color'); console.log(this.getAttribute('data-color');)}} /> */}
                          <img src={image.image} width={55} height={55} alt=""
                            data-color={index}
                            style={{ borderRadius: '50%' }}
                            className={`${isActive ? 'img-active' : ''}`}
                            // onClick={() => switchImage(index)}
                            onClick={() => switchImageHandler({ color_id: image.color_id, image: image.image })}
                          />
                        </div>
                      )
                    })}


                  </div>


                  <div className="d-flex align-items-center mb-4 pt-2">
                    <div className="input-group quantity mr-3" style={{ width: 130 }}>
                      <div className="input-group-btn" onClick={() => decreaseQty()}>
                        <PlusButton >
                          <FaMinus />
                        </PlusButton>
                      </div>
                      <input type="text" className="form-control bg-body border-0 text-center p-0" value={qty} readOnly />
                      <div className="input-group-btn" onClick={() => increaseQty()}>
                        <MinusButton >
                          <FaPlus />
                        </MinusButton>
                      </div>
                    </div>
                    <div onClick={() => addToCartHandler(pro_Details)}>
                      <PrimaryButton>
                        <FaShoppingCart /> Add To Cart
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Tabs info */}

            <div className="row px-xl-5">
              <div className="col">
                <div className="bg-white p-30">
                  <div className="nav nav-tabs mb-4">
                    <a className="nav-item nav-link text-dark active" data-toggle="tab" href="#tab-pane-1">Description</a>
                    <a className="nav-item nav-link text-dark" data-toggle="tab" href="#tab-pane-3">Reviews (0)</a>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="tab-pane-1">
                      <h4 className="mb-3">Product Description</h4>
                      <p>
                        {pro_Details.description}
                      </p>
                    </div>

                    {/* review Product */}
                    <div className="tab-pane fade" id="tab-pane-3">
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="mb-4">1 review for "Product Name"</h4>
                          <div className="media mb-4">
                            <img src="img/user.jpg" alt="" className="img-fluid mr-3 mt-1" style={{ width: 45 }} />
                            <div className="media-body">
                              <h6>John Doe<small> - <i>01 Jan 2045</i></small></h6>
                              <div><StarsRate stars={pro_Details.review} /></div>
                              <p>Diam amet duo labore stet elitr ea clita ipsum, tempor labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum.</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h4 className="mb-4">Leave a review</h4>
                          <small>Your email address will not be published. Required fields are marked *</small>
                          <div className="d-flex my-3">
                            <p className="mb-0 mr-2">Your Rating * :</p>
                            <div className="text-primary">
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                              <FaRegStar />
                            </div>
                          </div>
                          <form>
                            <div className="form-group">
                              <label htmlFor="message">Your Review *</label>
                              <textarea id="message" cols={30} rows={5} className="form-control" defaultValue={""} />
                            </div>
                            <div className="form-group">
                              <label htmlFor="name">Your Name *</label>
                              <input type="text" className="form-control" id="name" />
                            </div>
                            <div className="form-group">
                              <label htmlFor="email">Your Email *</label>
                              <input type="email" className="form-control" id="email" />
                            </div>
                            <div className="form-group mb-0">
                              <input type="submit" defaultValue="Leave Your Review" className="btn btn-primary px-3" />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >

          {/* Shop Detail End */}

          {/* Recomended Products Start */}
          <div className="container-fluid py-5">
            <SectionTitle>You May Also Like</SectionTitle>

            <div className="row px-xl-5">
              <div className="col">
                <ProductSlider data={pdata} />
              </div>
            </div>
          </div>
          {/* Recomended Products End */}
        </>
      ) : <NotFound />
      }

    </>
  )
}

export default ProductDetails
