import React, { useEffect, useState } from 'react'
// import SubNavbar from '../layout/SubNavbar'
import { ProductPageSlider, SectionTitle } from '../../Components'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';

function ShopPage() {

    const { name } = useParams();
    const { categories } = useSelector(state => state.categories);
    const [mianCat, setmainCat] = useState([]);

    useEffect(() => {
        console.log(name);
        console.log(categories);
        const filterSubCat = () => {
            // eslint-disable-next-line eqeqeq
            const subCat = categories.filter((item) => item.name == name)
            setmainCat(subCat[0]);

            console.log(subCat[0]);
        }
        filterSubCat()

    }, [name, categories, mianCat])

    return (
        <>
            {/* <SubNavbar /> */}
            <ProductPageSlider from={mianCat.name} />

            {/* Categories Start */}
            <div className="container-fluid pt-5">
                <SectionTitle>Categories</SectionTitle>
                <div className="row px-xl-5 pb-3">

                    {console.log(mianCat)}
                    {mianCat.length !== 0 ?
                        (
                            mianCat.all_subcategories.map((cat, idx) => {
                                return (
                                    <div key={idx} className="col-lg-3 col-md-4 col-sm-6 pb-1">
                                        <Link to={`/products-list/${cat.id}`} className="text-decoration-none" >
                                            <div className="cat-item d-flex align-items-center mb-4">
                                                <div className="overflow-hidden" style={{ width: 100, height: 100 }}>
                                                    <img className="img-fluid" src={cat.all_subcategories[0].image} alt='' />
                                                </div>
                                                <div className="flex-fill pl-3">
                                                    <h6 style={{ fontSize:"18px" }}>{cat.name}</h6>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })

                        ) : ""
                    }
                </div>
            </div>
            {/* Categories End */}


        </>
    )
}

export default ShopPage
