
import { FaHeart, FaSearch, FaShoppingCart } from "react-icons/fa";
import StarsRate from "./StarsRate";
import { IconButton, ImageSlider } from './index'
import { useDispatch } from "react-redux";
import { addToFav } from "../store/favSlice";
import { formatPrice } from "../utils/helper";

function ProductCard({ data, onAddClick }) {

    const dispatch = useDispatch()

    return (
        <>

            <div className="product-item bg-white mb-4">
                <div className="product-img position-relative overflow-hidden">
                    {/* <img className="img-fluid w-100" src={data.images[0]?.image} alt="" /> */}
                    <ImageSlider images={data.images} />
                    <div className="product-action">
                        <div onClick={onAddClick} data-toggle="modal" data-target=".bd-example-modal-lg">
                            <IconButton><FaShoppingCart /></IconButton>
                        </div>
                        <div onClick={() => dispatch(addToFav(data))}>
                            <IconButton ><FaHeart /></IconButton>
                        </div>
                        <IconButton href={`/product-detail/${data.id}`}><FaSearch /></IconButton>
                    </div>
                </div>
                <div className="text-center py-2"  >
                    {/* <a className="text-decoration-none text-primary" href='/' >
                        <div className="">
                            {data.name}
                        </div>
                    </a> */}
                    <div className="d-flex align-items-center justify-content-center mt-2">
                        <h5>{formatPrice(data.price)}</h5>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mb-1">
                        <StarsRate stars={data.review} />
                        <small>(99)</small>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mb-1">
                        {
                            data.colors?.map((color,idx)=>
                                <div key={idx} className="mr-2 img-cont">
                                    <img src={color.image} width={20} alt="" />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductCard
