import { configureStore } from "@reduxjs/toolkit"
import authReducer from './auth'
import cartReducer from './cartSlice'
import favReducer from './favSlice'
import categoryReducer from './categorySlice'
import productReducer from './productSlice'

const store=configureStore({
    reducer:{
        auth:authReducer,
        cart:cartReducer,
        categories:categoryReducer,
        products:productReducer,
        favourite:favReducer,
    }
})

export default store