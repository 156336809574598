import { Link } from 'react-router-dom'
import './buttons.css'

const PrimaryButton = (props) => {
    return (
        <>
            <Link to={props.href} className='btn primary-btn' >{props.children}</Link>
        </>
    )
}
const OutLineButton = (props) => {
    return (
        <>
            <Link to={props.href} className='btn outline-btn'>{props.children}</Link>
        </>
    )
}
const IconButton = (props) => {
    return (
        <>
            <Link to={props.href} className='btn icon-btn'>{props.children}</Link>
        </>
    )
}
const PlusButton = (props) => {
    return (
        <>
            <button className='btn op-btn'>{props.children}</button>
        </>
    )
}
const MinusButton = (props) => {
    return (
        <>
            <button className='btn op-btn'>{props.children}</button>
        </>
    )
}
const CancelButton = (props) => {
    return (
        <>
            <button className='btn cancel-btn'>{props.children}</button>
        </>
    )
}

const LargtButton = (props) => {
    return (
        <>
            <Link to={props.href} className='btn primary-btn d-block ' style={{ borderRadius:'0' }} >{props.children}</Link>
        </>
    )
}

export default PrimaryButton
export { OutLineButton, IconButton, MinusButton, PlusButton, CancelButton, LargtButton }