import { FaAngleRight, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa"


function Footer() {
    return (
        <>
            {/* Footer Start */}
            <div className="container-fluid bg-dark text-secondary mt-5 pt-5">
                <div className="row px-xl-5 pt-5 justify-content-center">
                    <div className="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
                        <h5 className="text-secondary text-uppercase mb-4">Get In Touch</h5>
                        <p className="mb-2"><FaMapMarkerAlt className="text-secondary mr-3" />123 Street, New York, USA</p>
                        <p className="mb-2"><FaEnvelope className="text-secondary mr-3" />info@example.com</p>
                        <p className="mb-0"><FaPhoneAlt className="text-secondary mr-3" />+012 345 67890</p>
                    </div>
                    <div className="col-md-4 mb-5">
                        <h5 className="text-secondary text-uppercase mb-4">Quick Shop</h5>
                        <div className="d-flex flex-column justify-content-start">
                            <a className="text-secondary mb-2" href="/"><FaAngleRight className="mr-2" />Home</a>
                            <a className="text-secondary mb-2" href="/shop/Men"><FaAngleRight className="mr-2" />Men Shop</a>
                            <a className="text-secondary mb-2" href="/shop/Women"><FaAngleRight className="mr-2" />Women Shop</a>
                            <a className="text-secondary mb-2" href="/shop/Kids"><FaAngleRight className="mr-2" />Kids Shop</a>
                        </div>
                    </div>
                    <div className="col-md-4 mb-5">
                        <h5 className="text-secondary text-uppercase mb-4">Newsletter</h5>
                        <p>Duo stet tempor ipsum sit amet magna ipsum tempor est</p>
                        <form >
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Your Email Address" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary">Sign Up</button>
                                </div>
                            </div>
                        </form>
                        {/* <h6 className="text-secondary text-uppercase mt-4 mb-3">Follow Us</h6>
                                <div className="d-flex">
                                    <a className="btn btn-primary btn-square mr-2" href="/"><FaTwitter /></a>
                                    <a className="btn btn-primary btn-square mr-2" href="/"><FaFacebookF /></a>
                                    <a className="btn btn-primary btn-square mr-2" href="/"><FaLinkedinIn /></a>
                                    <a className="btn btn-primary btn-square" href="/"><FaInstagram /></a>
                                </div> */}
                    </div>
                </div>
                <div className="row border-top mx-xl-5 py-4" style={{ borderColor: 'rgba(256, 256, 256, .1) !important' }}>
                    <div className="col-md-6 px-xl-0">
                        <p className="mb-md-0 text-center text-md-left text-secondary">
                            © <a className="text-secondary" href="/">Domain</a>. All Rights Reserved. Designed
                            by
                            <a className="text-secondary" href="https://htmlcodex.com"> HTML Codex</a>
                            <br />Distributed By: <a href="/" target="_blank">ThemeWagon</a>
                        </p>
                    </div>
                    <div className="col-md-6 px-xl-0 text-center text-md-right">
                    </div>
                </div>
            </div>
            {/* Footer End */}
        </>
    )
}

export default Footer
