import React from 'react'
import { FaBuilding, FaUserAlt } from 'react-icons/fa'
import './auth.css'
import { Link} from 'react-router-dom'


function RegisterOptions() {

    return (
        <>


            <div className="container-fluid my-form">
                <div className="row justify-content-center">
                    <div className="col-lg-8 ">
                        <div>
                            <div><h3 className='card-title text-align-center'>Who You Are</h3></div>
                            <div className="registration-form card " style={{ padding: '5rem 1rem' }}>
                                <div className='options'>
                                    <Link to='/register/company' className='company' >
                                        <FaBuilding className='register-icon' />
                                    </Link>
                                    <Link to='/register/user' className='user'>
                                        <FaUserAlt className='register-icon' />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default RegisterOptions
