import { FaMinus, FaPlus, FaShoppingCart, FaTimes } from "react-icons/fa"
import { ImageSlider, MinusButton, PlusButton, PrimaryButton, StarsRate } from "../../Components/index"
import { cartActions } from "../../store/cartSlice"
import { formatPrice } from "../../utils/helper"
import { useState } from "react"
import { useDispatch } from "react-redux"
import NotFound from "../Error/NotFound"
import './style.css'

function ProductModel({ product, onClose }) {

  const [qty, setQty] = useState(1)
  const dispatch = useDispatch()

  const increaseQty = () => {
    setQty((prevQty) => {
      return prevQty += 1;
    })
  }
  const decreaseQty = () => {
    setQty((prevQty) => {
      var newQty = prevQty - 1;
      if (newQty < 1) {
        newQty = 1;
      }
      return newQty;
    })
  }

  const [selectedImage, setSelectedImage] = useState({ color_id: product?.images[0].color_id, image: product?.images[0].image });
  const [selectedSize, setSelectedSize] = useState({ id: product?.sizes[0].id, size: product?.sizes[0].size });


  const switchImageHandler = (image) => {
    setSelectedImage(image);
    // console.log(selectedImage);
  };
  // const switchSizeHandler = (size) => {
  //   setSelectedSize(size);
  //   console.log(selectedSize);
  // };

  const addToCartHandler = (product) => {
    console.log(selectedImage)
    const tempPro = { ...product, qty: qty, image: selectedImage.image, color_id: selectedImage.color_id, size: selectedSize }
    console.log(tempPro)
    dispatch(cartActions.addToCart(tempPro))
  }


  return (

    <div className="modal d-block modal-backdrop" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      {/* {console.log(product)} */}
      <div className="modal-dialog modal-xl">
        <div className="modal-content ">

          {product ? (

            <div className="row px-xl-5">

              <div className="col-5 mb-30">
                <div id="product-carousel" className="carousel slide mt-2" data-ride="carousel">
                  <ImageSlider images={product.images} />
                </div>
              </div>
              <div className="col-lg-7 h-auto mb-30">
                <div className="h-100 bg-white p-30">
                  <h3>{product.name}</h3>
                  <div className="d-flex mb-3">

                    <StarsRate stars={product.review} />
                    <small className="pt-1">(99 Reviews)</small>
                  </div>
                  <h3 className="font-weight-semi-bold mb-4">{formatPrice(product.price)}</h3>
                  <p className="mb-4">{product.description}</p>

                  <div className="d-flex mb-3">
                    <strong className="text-dark mr-3">Sizes:</strong>
                    {console.log(selectedSize)}
                    {product.sizes.map((size, idx) => {
                      const isChecked =  selectedSize.id === size.id;
                      return (

                        <div key={idx} className="custom-control custom-radio custom-control-inline">
                          <input type="radio" checked={isChecked} className={`custom-control-input`} id={size.id} name="size"
                           onChange={() => setSelectedSize({ id: size.id, size: size.size })} />
                          <label className="custom-control-label" htmlFor={size.id}>{size.size}</label>
                        </div>
                      )
                    })}

                  </div>


                  <div className="d-flex mb-4 align-items-center">
                    <strong className="text-dark mr-3">Colors:</strong>
                    {/* {console.log(selectedImage)} */}
                    {product.images.map((image, index) => {

                      const isActive = selectedImage.color_id === image.color_id;

                      return (
                        <div key={image.id} className="mr-3" >
                          <img src={image.image} width={55} height={55} alt=""
                            data-color={index}
                            style={{ borderRadius: '50%' }}
                            className={`${isActive ? 'img-active' : ''}`}
                            onClick={() => switchImageHandler({ color_id: image.color_id, image: image.image })}
                          />
                        </div>
                      )
                    })}


                  </div>


                  <div className="d-flex align-items-center mb-4 pt-2">
                    <div className="input-group quantity mr-3" style={{ width: 130 }}>
                      <div className="input-group-btn" onClick={() => decreaseQty()}>
                        <PlusButton >
                          <FaMinus />
                        </PlusButton>
                      </div>
                      <input type="text" className="form-control bg-body border-0 text-center p-0" value={qty} readOnly />
                      <div className="input-group-btn" onClick={() => increaseQty()}>
                        <MinusButton >
                          <FaPlus />
                        </MinusButton>
                      </div>
                    </div>
                    <div onClick={() => addToCartHandler(product)}>
                      <PrimaryButton>
                        <FaShoppingCart /> Add To Cart
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          ) : <NotFound />
          }

          <button onClick={onClose} className="btn cancel-modal"><FaTimes /></button>
        </div>
      </div>
    </div>



  )
}

export default ProductModel
