import React from 'react'
import { FaShoppingCart, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { PrimaryButton, StarsRate } from '../Components'
import { useDispatch, useSelector } from 'react-redux'
import { formatPrice } from '../utils/helper'
import { cartActions } from '../store/cartSlice'
import { deleteFromFav } from '../store/favSlice'
import empty_fav from '../asset/img/cart1.png'

function Favourite() {

    const { favItems } = useSelector(state => state.favourite)
    const dispatch = useDispatch()

    const addToCartHandler = (product) => {
        const tempPro = { ...product, qty: 1 }
        dispatch(cartActions.addToCart(tempPro))
        dispatch(deleteFromFav(product))
        console.log('added success');
    }

    const emptyFav = () => {
        return (
            <>
                <div className="container-fluid mt-100">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body ">
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={empty_fav} width="200" className="img-fluid mr-3" alt="" />
                                        <h3><strong>Your Favourite Collection is Empty</strong></h3>
                                        <h4>Add something to make me happy :)</h4>
                                        <Link to="/" className="btn btn-primary cart-btn-transform m-3" data-abc="true">continue shopping</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>


            {favItems.length === 0 ? emptyFav() :
                (
                    <div className="container d-flex justify-content-center mt-5 mb-5">
                        <div className="row">
                            {

                                favItems.map((item, idx) => {
                                    return (

                                        <div key={idx} className="col-md-10 mb-4">
                                            {console.log(item)}
                                            <div className="card card-body position-relative">
                                                <div className='delete-fav' onClick={() => dispatch(deleteFromFav(item))}>
                                                    <FaTimes />
                                                </div>
                                                <div className="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row pt-3">
                                                    <div className="mr-3 mb-3 mb-lg-0">
                                                        <img src={item.image.image} width={130} height={150} alt='' />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-title font-weight-semibold text-primary">
                                                            <Link to="/" className='text-primary'>{item.name}</Link>
                                                        </h6>

                                                        <p className="mb-3"> {item.description} </p>

                                                    </div>
                                                    <div className="mt-3 mt-lg-0 ml-lg-3 text-center">
                                                        <h3 className="mb-0 font-weight-semibold">{formatPrice(item.price)}</h3>
                                                        <div>
                                                            <StarsRate stars={item.review} />
                                                        </div>
                                                        <div className="text-muted">1985 reviews</div>
                                                        <div className='mt-2' onClick={() => addToCartHandler(item)}>
                                                            <PrimaryButton>
                                                                <FaShoppingCart /> Add To Cart
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                )}



        </>
    )
}

export default Favourite
