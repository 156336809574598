
import { FaClipboardList, FaHeart, FaPowerOff, FaRegUserCircle, FaSearch, FaShoppingCart, FaUserAlt, } from "react-icons/fa";
import '../layout.css'
import './navbar.css'
import { Link, NavLink } from "react-router-dom";
import logo from '../../asset/img/logo1.png'
import { useSelector } from "react-redux";
import { useState } from "react";
import MegaMenu from "./MegaMenu";
import axios from "axios";
import { BASEURL } from "../../utils/apiURL";


function Navbar() {

    const { cartItems } = useSelector(state => state.cart)
    const { favItems } = useSelector(state => state.favourite)
    const { user } = useSelector(state => state.auth)
    const { categories } = useSelector(state => state.categories)

    const [showMegaMenu, setShowMegaMenu] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [suggestions, setSuggestions] = useState([])
    // const dispatch = useDispatch()
    // const navigate=useNavigate()

    const handleMouseEnter = (navItem) => {
        setShowMegaMenu(navItem);
    };

    const handleMouseLeave = () => {
        setShowMegaMenu(false);
    }


    const LogoutHandler = async () => {
        await axios.post(`${BASEURL}logout`, {
            token: user.token,

        }).then(res => {
            console.log(res.data)
            if (res.status === 200) {
                localStorage.removeItem('user');
                window.location.reload()
            }
        })
    }

    const handelSearch = async (e) => {
        let value = e.target.value
        setSearchInput(value)
        if (value.trim() !== '') {
            try {
                const response = await axios.post(`${BASEURL}search`, value);
                setSuggestions(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setSuggestions([]);
            }
        } else {
            setSuggestions([]);
        }
    }

    // const handleSuggestionClick = (suggestion) => {
    //     navigate(`/Products-search/${suggestion}`)
    //     // dispatch(getFilterdProducts({ suggestions: suggestion }))

    //     // try {
    //     //     const response = await axios.get(`${BASEURL}products`,{
    //     //         headers:{
    //     //             suggestion:suggestion
    //     //         }
    //     //     });
    //     //   } catch (error) {
    //     //     console.error('Error sending suggestion to server:', error);
    //     //   }
    // }

    return (
        <>
            {/* Navbar Start */}
            <div className="container-fluid bg-dark ">
                <div className="row px-xl-5">
                    {console.log(searchInput)}
                    <div className="col">
                        <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0">

                            <div >
                                <NavLink to="/" className="text-decoration-none">
                                    <img src={logo} alt="" width={100} />
                                </NavLink>
                            </div>

                            <NavLink to='/' className="text-decoration-none d-block d-lg-none">
                                <img src={logo} alt="" className="bg-light" />
                            </NavLink>
                            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">

                                {/* <div className="navbar-nav mr-auto py-0" style={{ marginBottom: "-4px" }}>
                                    <NavLink to="/home" className="nav-item nav-link ">Home</NavLink>
                                    <NavLink to="/shop/1" className="nav-item nav-link custom-nav-link">MAN</NavLink>
                                    <NavLink to="/shop/2" className="nav-item nav-link custom-nav-link">WOMEN</NavLink>
                                    <NavLink to="/shop/3" className="nav-item nav-link custom-nav-link">KIDS</NavLink>

                                </div> */}

                                <ul className="navbar-nav mr-auto py-0">
                                    <li><NavLink to="/" className="nav-item nav-link">HOME</NavLink></li>
                                    {categories && categories.map(mainCat => {
                                        // ....MEN.........
                                        return (
                                            <li key={mainCat.id} className="nav-item position-static " onMouseEnter={() => handleMouseEnter(mainCat.name)} onMouseLeave={handleMouseLeave}>
                                                <NavLink to={`/shop/${mainCat.name}`} className="nav-item nav-link main-cat" >{mainCat.name}</NavLink>
                                                {showMegaMenu === mainCat.name ? (
                                                    <>

                                                        {/* <div className="dropdown-menu w-100 mt-0 bg-white show" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>

                                                            <div className="container">
                                                                <div className="row my-4">

                                                                    {

                                                                        mainCat.all_subcategories.map(subcat => {
                                                                            // .......TOP.........
                                                                            return (
                                                                                <div className="col-2">
                                                                                    <h6>{subcat.name}</h6>
                                                                                    <div className="row">

                                                                                        {
                                                                                            subcat.all_subcategories.map(lastcat => {
                                                                                                // .......last layer items ..........//
                                                                                                return (
                                                                                                    <div className="col-3">
                                                                                                        <img src={lastcat.image} width={60} height={60} alt="" />
                                                                                                        <Link to="/#" className="">{lastcat.name}</Link>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                        </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <MegaMenu mainCat={mainCat} />

                                                    </>
                                                ) : null}
                                            </li>
                                        )
                                    })}

                                </ul>

                                <div className="col-lg-6 col-6 text-left">
                                    <form >
                                        <div className="input-group">
                                            <div className="search">
                                                <input type="text" className="form-control search-input" placeholder="Search for products"
                                                    onChange={handelSearch}
                                                />
                                                {console.log(suggestions)}
                                                {/* {suggestions?.length!==0 ?
                                                    <ul className="suggestions">
                                                        <li onClick={() => handleSuggestionClick('search1')} ><sapn>search1</sapn></li>
                                                        <li onClick={() => handleSuggestionClick('search2')} ><sapn>search2</sapn></li>
                                                        <li onClick={() => handleSuggestionClick('search3')} ><sapn>search3</sapn></li>
                                                    </ul>
                                                    : ""} */}

                                                {/* <ul>
                                                    {suggestions.map((suggestion, index) => (
                                                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                                            {suggestion}
                                                        </li>
                                                    ))}
                                                </ul> */}

                                                <div className="input-group-append search-btn">
                                                    <span className="input-group-text bg-transparent text-primary ">
                                                        <FaSearch className="icons-mid" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="navbar-nav ml-auto py-0 d-none d-lg-flex align-items-center">

                                    <Link to='/favourite' className="btn px-0 ml-2">
                                        < FaHeart className="icons-mid icons-light" />
                                        {favItems.length === 0 ? '' :
                                            <span className="badge text-secondary border border-secondary rounded-circle" style={{ paddingBottom: 2 }}>{favItems.length}</span>
                                        }
                                    </Link>

                                    <Link to='/cart' className="btn px-0 ml-2">
                                        <FaShoppingCart className="icons-mid icons-light" />
                                        {cartItems.length === 0 ? '' :
                                            <span className="badge text-secondary border border-secondary rounded-circle" style={{ paddingBottom: 2 }}>{cartItems.length}</span>
                                        }
                                    </Link>

                                    {user ?
                                        <div className="nav-item dropdown">
                                            <button className="nav-link dropdown-toggle" data-toggle="dropdown"><FaUserAlt className="icons-mid icon-light" /></button>
                                            <div className="dropdown-menu profile-menu rounded-0 border-0 m-0 bg-">
                                                <div className="user-drop-head">
                                                    <span>{user.email}</span>
                                                </div>
                                                <Link to="/order-list" className="dropdown-item"><FaClipboardList className="icons-mid icons-dark" /> My Orders</Link>
                                                <Link to="/edit-profile" className="dropdown-item"> <FaRegUserCircle className="icons-mid icons-dark" /> Profile</Link>
                                                {/* <Link to="/login" className="dropdown-item"><FaSignInAlt className="icons-mid icons-dark" /> LogIn</Link>
                                                <Link to="/register" className="dropdown-item"><FaUserPlus className="icons-mid icons-dark" /> Register</Link> */}
                                                <Link to="/" className="dropdown-item" onClick={() => LogoutHandler()}><FaPowerOff className="icons-mid icons-dark" /> LogOut</Link>
                                            </div>
                                        </div>
                                        : (
                                            <>
                                                <Link to="/login" className="nav-item nav-link">Login</Link>/
                                                <Link to="/register" className="nav-item nav-link">Register</Link>
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                        </nav>
                    </div>
                </div >
            </div >
            {/* Navbar End */}
        </>
    )
}
export default Navbar














// function Navbar() {

//     const { cartItems } = useSelector(state => state.cart)
//     const { favItems } = useSelector(state => state.favourite)
//     const { user } = useSelector(state => state.auth)
//     const { error,categories } = useSelector(state => state.categories)
//     const dispatch = useDispatch()

//     const [showMegaMenu, setShowMegaMenu] = useState(false)
//     const handleMouseEnter = () => {
//         setShowMegaMenu(true);
//     };
//     const handleMouseLeave = () => {
//         setShowMegaMenu(false);
//     };

//     useEffect(() => {
//         dispatch(fetchCategories())
//     }, [dispatch]);

//     if (error) {
//         swal('Error',error,'error')
//       }

//     return (
//         <>
//         {console.log(categories)}
//             {/* Navbar Start */}
//             <div className="container-fluid bg-dark ">
//                 <div className="row px-xl-5">

//                     <div className="col">
//                         <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0">

//                             <div >
//                                 <NavLink to="/" className="text-decoration-none">
//                                     <img src={logo} alt="" width={100} />
//                                 </NavLink>
//                             </div>

//                             <NavLink to='/' className="text-decoration-none d-block d-lg-none">
//                                 <img src={logo} alt="" className="bg-light" />
//                             </NavLink>
//                             <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
//                                 <span className="navbar-toggler-icon" />
//                             </button>
//                             <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">

//                                 {/* <div className="navbar-nav mr-auto py-0" style={{ marginBottom: "-4px" }}>
//                                     <NavLink to="/home" className="nav-item nav-link ">Home</NavLink>
//                                     <NavLink to="/shop/1" className="nav-item nav-link custom-nav-link">MAN</NavLink>
//                                     <NavLink to="/shop/2" className="nav-item nav-link custom-nav-link">WOMEN</NavLink>
//                                     <NavLink to="/shop/3" className="nav-item nav-link custom-nav-link">KIDS</NavLink>

//                                 </div> */}

//                                 <ul className="navbar-nav mr-auto py-0">
//                                     <li><NavLink to="/" className="nav-item nav-link">Home</NavLink></li>

//                                     <li className="nav-item position-static" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//                                         <NavLink to="/men" className="nav-item nav-link" >Man</NavLink>
//                                         {showMegaMenu ? (
//                                             <div className="dropdown-menu w-100 mt-0 bg-white show" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>

//                                                 <div className="container">
//                                                     <div className="row my-4">
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <Link to="/#" className="list-group-item list-group-item-action maincat">Main MAAAAN Category</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</Link>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action maincat">Maaaan Category</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</NavLink>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action maincat">Maaan Category</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</NavLink>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3">
//                                                             <img src={men} alt="" style={{ width: '70%' }} />
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         ) : null}

//                                     </li>

//                                     <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//                                         <NavLink to="/women" className="nav-item nav-link" >Women</NavLink>
//                                         {showMegaMenu ? (
//                                             <div className="dropdown-menu w-100 mt-0 bg-white show" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>

//                                                 <div className="container">
//                                                     <div className="row my-4">
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action maincat">woooooo Category</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</NavLink>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action maincat">wooooooo Category</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</NavLink>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action maincat">woooooooo Category</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</NavLink>
//                                                                 <NavLink to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</NavLink>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3">
//                                                             <img src={women} alt="" style={{ width: '70%' }} />
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         ) : null}

//                                     </li>

//                                     <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//                                         <NavLink to="/Kids" className="nav-item nav-link " >Kids</NavLink>
//                                         {showMegaMenu ? (
//                                             <div className="dropdown-menu w-100 mt-0 bg-white show" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>

//                                                 <div className="container">
//                                                     <div className="row my-4">
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <Link to="/#" className="list-group-item list-group-item-action maincat">kiiii Category</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</Link>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <Link to="/#" className="list-group-item list-group-item-action maincat">kiiii Category</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</Link>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
//                                                             <div className="list-group list-group-flush">

//                                                                 <Link to="/#" className="list-group-item list-group-item-action maincat">kiiiiiiii Category</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Dolor sit</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Amet consectetur</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Cras justo odio</Link>
//                                                                 <Link to="/#" className="list-group-item list-group-item-action subcat">Adipisicing elit</Link>
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-6 col-lg-3">
//                                                             <img src={kids} alt="" style={{ width: '70%' }} />
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         ) : null}

//                                     </li>
//                                 </ul>

//                                 <div className="col-lg-6 col-6 text-left">
//                                     <form >
//                                         <div className="input-group">
//                                             <div className="search">
//                                                 <input type="text" className="form-control search-input" placeholder="Search for products" />
//                                                 <div className="input-group-append search-btn">
//                                                     <span className="input-group-text bg-transparent text-primary ">
//                                                         <FaSearch className="icons-mid" />
//                                                     </span>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </form>
//                                 </div>

//                                 <div className="navbar-nav ml-auto py-0 d-none d-lg-flex align-items-center">

//                                     <Link to='/favourite' className="btn px-0 ml-2">
//                                         < FaHeart className="icons-mid icons-light" />
//                                         {favItems.length === 0 ? '' :
//                                             <span className="badge text-secondary border border-secondary rounded-circle" style={{ paddingBottom: 2 }}>{favItems.length}</span>
//                                         }
//                                     </Link>

//                                     <Link to='/cart' className="btn px-0 ml-2">
//                                         <FaShoppingCart className="icons-mid icons-light" />
//                                         {cartItems.length === 0 ? '' :
//                                             <span className="badge text-secondary border border-secondary rounded-circle" style={{ paddingBottom: 2 }}>{cartItems.length}</span>
//                                         }
//                                     </Link>

//                                     {user ?
//                                         <div className="nav-item dropdown">
//                                             <button className="nav-link dropdown-toggle" data-toggle="dropdown"><FaUserAlt className="icons-mid icon-light" /></button>
//                                             <div className="dropdown-menu profile-menu rounded-0 border-0 m-0 bg-">
//                                                 <Link to="/order-list" className="dropdown-item"><FaClipboardList className="icons-mid icons-dark" /> My Orders</Link>
//                                                 <Link to="/edit-profile" className="dropdown-item"> <FaRegUserCircle className="icons-mid icons-dark" /> Profile</Link>
//                                                 {/* <Link to="/login" className="dropdown-item"><FaSignInAlt className="icons-mid icons-dark" /> LogIn</Link>
//                                                 <Link to="/register" className="dropdown-item"><FaUserPlus className="icons-mid icons-dark" /> Register</Link> */}
//                                                 <Link to="/" className="dropdown-item" onClick={() => dispatch(userLogOut())}><FaPowerOff className="icons-mid icons-dark" /> LogOut</Link>
//                                             </div>
//                                         </div>
//                                         : (
//                                             <>
//                                                 <Link to="/login" className="nav-item nav-link">Login</Link>/
//                                                 <Link to="/register" className="nav-item nav-link">Register</Link>
//                                             </>
//                                         )
//                                     }

//                                 </div>
//                             </div>
//                         </nav>
//                     </div>
//                 </div>
//             </div>
//             {/* Navbar End */}
//         </>
//     )
// }
// export default Navbar