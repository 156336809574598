import "./style.css"
import noItem from '../../asset/img/noItem.jpg'
import { useParams } from "react-router-dom"
import { Loader, ProductCard, SectionTitleSm } from "../../Components"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {  getFilterdProducts } from "../../store/productSlice"
import ProductModel from "./ProductModel"
import axios from "axios"
import { BASEURL } from "../../utils/apiURL"


function ProductsPage() {

    const { id } = useParams()
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [sizes, setSizes] = useState(null);
    const [colors, setColors] = useState(null);
    const [selectedColors, setSelectedColors] = useState([]);
    const [filterBySize, setFilterBySize] = useState([]);
    const [priceRang, setPriceRang] = useState({
        min: 20000,
        max: 100000,
    });
    const [rangeModify, setRangeModify] = useState(false)


    // useEffect(() => {
    //     dispatch(fetchProductCategory(id))
    // }, [dispatch, id])

    const { products, loading } = useSelector(state => state.products)


    useEffect(() => {
        const fetchColorsSizes = async () => {
            await axios.get(`${BASEURL}all/colors/sizes`)
                .then((res) => {
                    setColors(res.data.data.colors);
                    setSizes(res.data.data.sizes);
                })
        }
        if (!(colors && sizes)) {
            fetchColorsSizes();
        }

    }, [colors, sizes])


    useEffect(() => {
        console.log(priceRang.min, priceRang.max)
        let dataFilter = {
            category_id: id,
            colors: selectedColors,
            sizes: filterBySize,
        };
        if (!rangeModify) {
            dispatch(getFilterdProducts(dataFilter))

        } else {
            dataFilter = {
                ...dataFilter,
                low_price: parseFloat(priceRang.min),
                top_price: parseFloat(priceRang.max)
            }
            const timeout = setTimeout(() => {
                dispatch(getFilterdProducts(dataFilter))
            }, 1000);
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [dispatch, selectedColors, filterBySize, id, priceRang.min, priceRang.max, rangeModify])

    if (loading) {
        return <Loader />
    }

    const handleMinRange = (e) => {
        const minValue = parseInt(e.target.value);
        const maxValue = parseInt(priceRang.max);
        if (minValue <= maxValue) {
            setPriceRang({ ...priceRang, min: e.target.value })
        } else {
            setPriceRang({ ...priceRang, min: priceRang.max - 1000 })
        }
        setRangeModify(true)

    }

    const handleMaxRange = (e) => {
        const maxValue = parseInt(e.target.value);
        const minValue = parseInt(priceRang.min);
        if (maxValue > minValue) {
            setPriceRang({ ...priceRang, max: e.target.value })
        }
        setRangeModify(true)
    }



    const handelSelectColors = (color_id) => {
        if (selectedColors.includes(color_id)) {
            setSelectedColors(selectedColors.filter((color) => color !== color_id));
        } else {
            setSelectedColors([...selectedColors, color_id]);
        }
    }
    const handelFilterBySize = (size_id) => {
        if (filterBySize.includes(size_id)) {
            setFilterBySize(filterBySize.filter((size) => size !== size_id));
        } else {
            setFilterBySize([...filterBySize, size_id]);
        }
    }

    const handelAddCart = (pro) => {
        setSelectedProduct(pro);
        setShowModal(!showModal)
    }

    const onCloseHandel = () => {
        setShowModal(!showModal)
    }

    const clearFilter = () => {
        setFilterBySize([]);
        setSelectedColors([]);
    }

    const noItemFound = () => {
        return (
            <div className="container-fluid mt-100">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">

                            <div className="card-body ">
                                <div className="col-sm-12 text-center">
                                    <img src={noItem} width="200" className="img-fluid mr-3" alt="" />
                                    <h3><strong>There is No Products Found</strong></h3>
                                    <button className="btn btn-primary cart-btn-transform m-3"
                                        onClick={() => clearFilter()}
                                    >
                                        Clear Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>

            <div>
                {/* Breadcrumb Start */}
                <div className="container-fluid">
                    <div className="row px-xl-5">
                        <div className="col-12">
                            <nav className="breadcrumb bg-white mb-30">
                                <a className="breadcrumb-item text-dark" href="/">Home</a>
                                <span className="breadcrumb-item active">Shop List</span>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* Breadcrumb End */}

                {/* Shop Start */}
                <div className="container-fluid">
                    <div className="row px-xl-5">
                        {/* Shop Sidebar Start */}
                        <div className="col-lg-3 col-md-4">
                            {/* Price Start */}
                            <SectionTitleSm>Filter by Price</SectionTitleSm>
                            <div className="bg-white p-4 mb-30">

                                <form>
                                    {/* <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                                        <input type="checkbox" className="custom-control-input" defaultChecked id="price-all" />
                                        <label className="custom-control-label" htmlFor="price-all">All Price</label>
                                    </div> */}
                                    {/* <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                                        <input type="checkbox" className="custom-control-input" id="price-1" />
                                        <label className="custom-control-label" htmlFor="price-1"> - $100</label>
                                    </div>
                                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                                        <input type="checkbox" className="custom-control-input" id="price-2" />
                                        <label className="custom-control-label" htmlFor="price-2">$100 - $200</label>
                                        <span className="badge border font-weight-normal">295</span>
                                    </div>
                                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                                        <input type="checkbox" className="custom-control-input" id="price-3" />
                                        <label className="custom-control-label" htmlFor="price-3">$200 - $300</label>
                                        <span className="badge border font-weight-normal">246</span>
                                    </div>
                                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                                        <input type="checkbox" className="custom-control-input" id="price-4" />
                                        <label className="custom-control-label" htmlFor="price-4">$300 - $400</label>
                                        <span className="badge border font-weight-normal">145</span>
                                    </div>
                                    <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between">
                                        <input type="checkbox" className="custom-control-input" id="price-5" />
                                        <label className="custom-control-label" htmlFor="price-5">$400 - $500</label>
                                        <span className="badge border font-weight-normal">168</span>
                                    </div> */}

                                    {/* <div class="range-input">
                                        <input type="range" name="min" className="min" min="0" max="1000" value="300" step="10" onChange={handleInput}/>
                                        <label className="">Min</label>
                                        <input type="range" name="max" className="max" min="0" max="1000" value="200" step="10" onChange={handleInput}/>
                                    </div> */}

                                    {/* <div className="form-group row">
                                        <div className="col-md-9 range-input">
                                            <label htmlFor="formControlRange">Example Range input</label> 
                                            <input type="range" name="min" min={1} max={100} defaultValue={1} className="form-control-range range-slider" id="minRange" 
                                            onChange={handleInput}
                                            />
                                            <input type="range" name="max" min={1} max={100} defaultValue={50} className="form-control-range range-slider" id="maxRange" 
                                            onChange={handleInput}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <span id="demo">min{priceRang.min}</span><br/>
                                            <span id="demo">max{priceRang.max}</span>
                                        </div>
                                    </div> */}
                                    <div className="range">
                                        <div className="range-slider">
                                            <span className="range-selected" />
                                        </div>
                                        <div className="range-input">
                                            <input type="range" name="min" className="min" min={20000} max={100000} step={10000}
                                                value={priceRang.min} onChange={handleMinRange}
                                            />
                                            <input type="range" name="max" className="max" min={20000} max={350000} step={10000}
                                                value={priceRang.max} onChange={handleMaxRange}
                                            />

                                        </div>
                                        <div className="range-values">
                                            <span >Min: {priceRang.min}</span>
                                            <span >Max: {priceRang.max}</span>
                                        </div>
                                    </div>


                                </form>
                            </div>
                            {/* Price End */}

                            {/* Color Start */}
                            <SectionTitleSm>Filter by color</SectionTitleSm>
                            <div className="bg-white p-4 mb-30">
                                <form>

                                    <div className="row">
                                        {colors?.map((color, idx) => {

                                            return (
                                                <div key={idx} className={`mr-2 mb-1 img-cont col-2`}
                                                    onClick={() => { handelSelectColors(color.id) }}
                                                >
                                                    <img src={color.image} className={`${selectedColors.includes(color.id) ? 'selected-item' : ''}`} alt="" />
                                                </div>
                                            )
                                        })}
                                    </div>

                                </form>
                            </div>
                            {/* Color End */}

                            {/* Size Start */}
                            <SectionTitleSm>Filter by size</SectionTitleSm>
                            <div className="bg-white p-4 mb-30">
                                <form>
                                    <div className="container">
                                        <div className="row">
                                            {sizes?.map((size, idx) => {
                                                return (

                                                    <div key={idx} className="col-4 custom-control custom-checkbox mb-3">
                                                        <input type="checkbox" className="custom-control-input" id={`size${size.id}`}
                                                            onChange={() => handelFilterBySize(size.id)}
                                                            checked={filterBySize?.includes(size.id)}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`size${size.id}`}>{size.size}</label>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* Size End */}
                        </div>
                        {/* Shop Sidebar End */}


                        {/* Shop Product Start */}
                        <div className="col-lg-9 col-md-8">
                            <div className="row pb-3">

                                {products.length !== 0 ? (products.map((p, idx) => (

                                    <div key={idx} className="col-lg-4 col-md-6 col-sm-6 pb-1">
                                        <ProductCard
                                            data={p}
                                            onAddClick={() => handelAddCart(p)}
                                        />
                                    </div>
                                ))) :
                                    noItemFound()
                                }
                            </div>

                            {/* <div className="col-12">
                                <nav>
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item disabled"><a className="page-link" href="/">Previous</a></li>
                                        <li className="page-item active"><a className="page-link" href="/">1</a></li>
                                        <li className="page-item"><a className="page-link" href="/">2</a></li>
                                        <li className="page-item"><a className="page-link" href="/">3</a></li>
                                        <li className="page-item"><a className="page-link" href="/">Next</a></li>
                                    </ul>
                                </nav>
                            </div> */}
                        </div>

                        {showModal ? (
                            <ProductModel onClose={onCloseHandel} product={selectedProduct} />
                        ) : ""}
                    </div>
                    {/* Shop Product End */}
                </div>
            </div>
            {/* Shop End */}
        </>
    )
}

export default ProductsPage
