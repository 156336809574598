import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/store'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe ("pk_test_VOOyyYjgzqdm8I3SrBqmh9qY");
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
   <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Provider>
);
