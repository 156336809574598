import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
// import axios from '../utils/axios';
import { BASEURL } from '../utils/apiURL'

const getUser = () => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(localStorage.getItem('user'))
    }
    return null
}


const initialState = {
    user: getUser(),
    error: null,
    loading: false,
    success_msg: null,
    first_reg:true
}


export const userLogIn = createAsyncThunk('auth/login',
    async (userDetails) => {
        const response = await axios.post(`${BASEURL}login`, userDetails);
        if (response.data.code === 200) {
            const userData = response.data.user;
            localStorage.setItem('user', JSON.stringify(userData))
            return response.data;
        }
        else {
            console.log(response.data);
            // throw new Error(response.data.errors)
            return response.data.errors;
        }
    }
);

export const userRegister = createAsyncThunk('auth/user/register',
    async (userDetails) => {

        const response = await axios.post(`${BASEURL}register`, userDetails)
        if (response.data.code === 200) {
            const userData = response.data.user;
            localStorage.setItem('user', JSON.stringify(userData))
            return response.data;

        }
        else {
            console.log(response.data);
            // throw new Error(response.data.errors)
            return response.data.errors;
        }

    })


export const companyRegister = createAsyncThunk('auth/compay/register',
    async (compDetails) => {
        try {
            const response = await axios.post(`${BASEURL}company/register`, compDetails)
            if (response.data.code === 200) {
                console.log(response.data);
                return { message: response.data.msg }; // Return success message in an object
            } else if (response.data.code === 422) {
                console.log(response.data);
                return response.data.errors;
            }
        } catch (error) {
            throw new Error(error.response.data.message || 'Error occurred while fetching categories');
        }

    })


const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setErrorEmpty(state) {
            state.error = null
        },
        setFirstReg(state){
            state.first_reg=false
        }
    },
    extraReducers: (builder) => {
        builder

            // .......Login.........
            .addCase(userLogIn.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogIn.fulfilled, (state, action) => {
                state.loading = false;
                console.log(action.payload);
                state.error = action.payload;
                state.user = action.payload.user;
            })
            .addCase(userLogIn.rejected, (state, action) => {
                console.log(action.error);
                state.loading = false;
                state.user = null;
                state.error = action.error
            })
            // .......Register.........
            .addCase(userRegister.pending, (state) => {
                state.loading = true;
                state.error = [];
            })
            .addCase(userRegister.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user;
                console.log(action.payload);
                state.error = action.payload;
                state.first_reg=false

            })
            .addCase(userRegister.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(companyRegister.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            // .addCase(companyRegister.fulfilled,(state,action)=>{
            //     state.loading=false;
            //     // state.success_msg=action.payload
            //     state.error=action.payload;
            // })
            // .addCase(companyRegister.rejected,(state,action)=>{
            //     state.loading=false
            //     state.error=action.error.message
            // })

            .addCase(companyRegister.fulfilled, (state, action) => {
                console.log(action.payload);
                state.loading = false;
                state.success_msg = action.payload.message;
                state.error = action.payload;
            })

            // .......Company Register Failure.........
            .addCase(companyRegister.rejected, (state, action) => {
                state.loading = false;
                state.success_msg = null; // Clear any previous success message

                // Check if the action has payload and it contains the error message
                if (action.payload) {
                    state.error = action.payload;
                } else {
                    // If the action has no payload or the payload doesn't contain the error message, use a default error message
                    state.error = "An error occurred while processing the registration.";
                }
            })

    }
});

export const authActions = auth.actions

export default auth.reducer