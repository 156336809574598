import { FaUserAlt } from "react-icons/fa"
import '../pages.css'
import { Link } from "react-router-dom"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { authActions, userLogIn } from "../../store/auth";
import Swal from "sweetalert2";


function UserLogin() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.auth)
  
    const loginHandler = (e) => {
      // prevent the form submission and refreshing of page
      e.preventDefault();
      let userDetail = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      }
      dispatch(userLogIn(userDetail))
    }
  
  
  
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'ERROR',
        html: `
          <div>
            <p>${error.email?error.email:''}</p>
            <p>${error.password?error.password:''}</p>
            <p>${error.error?error.error:''}</p>
          </div>
        `,
        confirmButtonText: 'OK',
      });
  
      dispatch(authActions.setErrorEmpty())
    }
  
  
  
    return (
  
      <>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
  
              <div className="registration-form">
                <form onSubmit={loginHandler}>
                  <div className="form-icon">
                    <span><FaUserAlt /></span>
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control item" id="phone-number" placeholder="Your Email" ref={emailRef} />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control item" id="password" placeholder="Password" ref={passwordRef} />
                  </div>
                  <div className="form-group">
                    <button type="submit" className={`btn btn-block create-account ${loading ? 'disabled' : ''}`}>{loading ? 'Log In...' : 'Log In'}</button>
                  </div>
                  <Link className="small text-muted" to="/">Forgot password?</Link>
                  <p className="mb-5 pb-lg-2" style={{ color: '#393f81', fontSize: '14px' }}>Don't have an account? <Link to="/register" style={{ color: '#393f81' }}>Register here</Link></p>
                </form>
              </div>
            </div>
          </div>
        </div>
  
      </>
    )
}

export default UserLogin
