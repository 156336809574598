import { BASEURL } from "../../utils/apiURL";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { cartActions } from "../../store/cartSlice";


function PayButton({ cartItems }) {

    const { user } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    // const stripe = useStripe();
    // const elements = useElements();

    const checkOutHandler = () => {
        setLoading(true)
        axios.post(`${BASEURL}order`, {
            cartItem: cartItems,
            token: user.token
        })
            .then((res) => {
                const result = res.data;
                if (result.data.url) {
                    window.location.href = result.data.url;
                    setLoading(false)
                    dispatch(cartActions.clearCart())
                }
            }).catch(error => {
                return (
                    console.log(error.message)
                )
            })
    }


    return (
        <button className={`btn primary-btn d-block ${loading?'disabel':""}`} onClick={checkOutHandler} >Checkout</button>
    )
}

export default PayButton
