import React from 'react';
import './component.css';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';

const StarsRate = ({ stars }) => {
  const fullStars = Math.floor(stars);
  const halfStars = Math.floor(stars * 2) % 2;
  const emptyStars = 5 - fullStars - halfStars;

  const starList = [];
  for (let i = 0; i < fullStars; i++) {
    starList.push(<FaStar key={i} />);
  }
  if (halfStars) {
    starList.push(<FaStarHalfAlt key={fullStars} />);
  }
  for (let i = 0; i < emptyStars; i++) {
    starList.push(<FaRegStar key={fullStars + halfStars + i} />);
    }

    return (
      <div className="star mr-2">
        {starList}
      </div>
    );
  };

export default StarsRate;