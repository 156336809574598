import React, {  useEffect, useState } from 'react'
import { FaUserAlt } from "react-icons/fa"
import '../pages.css'
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import {  userRegister } from "../../store/auth";


function RegisterUser() {
    const nameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const birthDayRef = useRef();
    const dispatch = useDispatch();
    const { user, loading } = useSelector(state => state.auth)
    const [gender, setGender] = useState('')
    const navigate = useNavigate()


    const registerHandler = (e) => {
        e.preventDefault();
        let userData = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            gender: gender,
            birthDay: birthDayRef.current.value,
        }
        dispatch(userRegister(userData))

    }

    useEffect(() => {
        if (user) {
            navigate('/questions')
        
        }
    }, [navigate, user]);


    // if (error !== null) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'ERROR',
    //         html: `
    //         <div>
    //           <p>${error.name ? error.name : ''}</p>
    //           <p>${error.email ? error.email : ''}</p>
    //           <p>${error.password ? error.password : ''}</p>
    //           <p>${error.error ? error.error : ''}</p>
    //         </div>
    //       `,
    //         confirmButtonText: 'OK',
    //     });
    //     dispatch(authActions.setErrorEmpty())

    // }


    return (
        <>
            <div className="container ">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="registration-form">
                            <form onSubmit={registerHandler}>
                                <div className="form-icon">
                                    <span><FaUserAlt /></span>

                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control item" id="username" placeholder="Username" ref={nameRef} />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control item" id="phone-number" placeholder="Your Email" ref={emailRef} />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control item" id="password" placeholder="Password" ref={passwordRef} />
                                </div>
                                <div className="form-group">
                                    <input type="date" className="form-control item" id="birthDay" placeholder="birthDay" ref={birthDayRef} />
                                </div>
                                <div className="form-group">
                                    <input type='radio' className='form-check-input' name='gender' id='genderm' value='male'
                                        checked={gender === 'male'}
                                        onChange={(e) => { setGender(e.target.value) }} />
                                    <label className='form-check-label' htmlFor='genderm'>Male</label>
                                </div>
                                <div className="form-group">
                                    <input className="form-check-input" type="radio" name="gender" id="genderf" value='female'
                                        checked={gender === 'female'}
                                        onChange={(e) => { setGender(e.target.value) }} />
                                    <label className='form-check-label' htmlFor='genderf'>Female</label>
                                </div>
                                <div className="form-group">
                                    <button type="submite" className={`btn btn-block create-account ${loading ? 'disabled' : ''}`}>{loading ? 'Create Account...' : 'Create Account'}</button>
                                </div>
                                <p className="mb-5 pb-lg-2" style={{ color: '#393f81', fontSize: '14px' }}>You allredy have an account? <Link to="/login" style={{ color: '#393f81' }}>Login here</Link></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterUser
