import React from 'react'
import './notfound.css'
import { Link } from 'react-router-dom'
function NotFound(props) {
    return (
        <>

            <div>
                <h1>404 Error Page </h1>
                <section className="error-container">
                    <span className="four"><span className="screen-reader-text">4</span></span>
                    <span className="zero"><span className="screen-reader-text">0</span></span>
                    <span className="four"><span className="screen-reader-text">4</span></span>
                </section>
                <h3 className='text-center'>
                Product Not Found
                </h3>
                <div className="link-container">
                    <Link to='/home' className="more-link" rel="noreferrer">Back To Home</Link>
                </div>
            </div>

        </>
    )
}

export default NotFound
