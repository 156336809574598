import React from 'react'
import { Carousel } from '../Components'
import { Recent, RecommendedProuct } from '../Sections'
import { useSelector } from 'react-redux'


function Home() {

const {user}=useSelector(state=>state.auth)

    return (
        <>
            <Carousel />
            {user? <RecommendedProuct /> :""}
            <Recent />
        </>
    )
}

export default Home
