
import axios from 'axios'
import { ProductSlider, SectionTitle } from '../Components'
import { BASEURL } from '../utils/apiURL'
import { useEffect, useState } from 'react'
import swal from 'sweetalert'

function Recent() {

  const [recentProducts, setRecentProducts] = useState(null)

  useEffect(() => {
    const fetchRecent = async () => {
      const res = await axios.get(`${BASEURL}recent_products`)
      if (res.data.code === 200) {
        setRecentProducts(res.data.data);
      }
      else {
        swal("Error", res.data.mesage, "error")
      }
    }

    if (!recentProducts)
      fetchRecent();


  }, [recentProducts])


  return (
    <>
      {recentProducts ? (
        <div className="container-fluid pt-5 pb-3">
          <SectionTitle> Recent Products</SectionTitle>
          <div className="row px-xl-5">
            {console.log(recentProducts)}


            <ProductSlider data={recentProducts} />


            {/* <div key={p.id} className="col-lg-3 col-md-4 col-sm-6 pb-1" > */}
            {/* <ProductCard data={p} /> */}
            {/* </div> */}

          </div>
        </div>

      ) : ""}
    </>
  )
}

export default Recent
