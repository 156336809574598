import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./component.css";
import Slider from "react-slick";
import { FaAngleLeft, FaAngleRight, } from "react-icons/fa";


function ImageSlider({  images }) {
    
    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <button onClick={onClick} className="btn next-arrow">
                <FaAngleRight />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <button onClick={onClick} className="btn prev-arrow">
                <FaAngleLeft />
            </button>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: false,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        // initialSlide: selectedColorIndex, 
      };
    
      return (
        <Slider {...settings}>
          {images?.map((image) => {
            return (
              // <div key={image.id} className={`div_${image.color_id}`}>
              <div key={image.id} >
                <img className="w-100 h-100" src={image.image} alt="" />
              </div>
            );
          })}
        </Slider>
      );
    }
    
    export default ImageSlider;