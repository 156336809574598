// export const formatPrice = (price) => {
//     return new Intl.NumberFormat('en-SY', {
//         style: 'currency',
//         currency: "SYP"
//     }).format(price);
// }

export const formatPrice = (price) => {
    const numericPrice = Number(price);
  
    if (isNaN(numericPrice)) {
      return "Invalid Price";
    }
  
    const formattedPrice = numericPrice.toLocaleString("en-US");

  return `${formattedPrice} SYR`;
};
  