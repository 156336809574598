import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import './asset/css/style.min.css'
import './asset/css/style.css'
import './asset/js/main'
import { Login,UserLogin, NotFound, RegisterCompany, RegisterOptions, RegisterUser } from './Pages';
import Master from './layout/Master';
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategories } from "./store/categorySlice";
import { useEffect } from "react";
import { Loader } from './Components';



function App() {

  const { user } = useSelector(state => state.auth)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCategories())
  }, [dispatch])

  const { loading } = useSelector(state => state.categories)

  if (loading) {
    return <Loader/>
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path='/*' element={<Master />} />
          <Route path='/404' element={<NotFound />} />
          <Route path='/loader' element={<Loader />} />
          <Route path='/login' exact element={user ? <Navigate to='/home' /> : <Login />} />
          <Route path='/login/user' exact element={user ? <Navigate to='/home' /> : <UserLogin />} />
          <Route path='/register' element={user ? <Navigate to='/home' /> : <RegisterOptions />} exact={true} />
          <Route path='/register/company' element={user ? <Navigate to='/home' /> : <RegisterCompany />} />
          {/* <Route path='/register/user' element={user ? <Navigate to='/home' /> :<RegisterUser />} /> */}
          <Route path='/register/user' element={ <RegisterUser />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
