
import {  ProductSlider, SectionTitle } from '../Components'
import axios from 'axios'
import { BASEURL } from '../utils/apiURL'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'


function RecommendedProuct() {

  const {user}=useSelector(state=>state.auth)
  const [recomendPro,setRecomendPro]=useState(null)
  // const [loader,setLoader]=useState(true)

  useEffect(() => {
    console.log(user.token)
    axios.get(`${BASEURL}getProductsNew`,{
      headers:{
        auth_token:user.token
      }
    }).then(res=>{

      if(res.data.code===200){
        console.log(res.data.data)
        setRecomendPro(res.data.data)
        // setLoader(false)
      }
    })

  }, [user.token])
  

  return (
    <>
    {recomendPro ? (
      <div className="container-fluid pt-5 pb-3">
        <SectionTitle> You May Like</SectionTitle>
        {/* {console.log(recomendPro)} */}
        <div className="row px-xl-5">
          {/* {recomendPro?.map(p => {
            return (
              <div key={p.id} className="col-lg-3 col-md-4 col-sm-6 pb-1" >
                <ProductCard data={p} />
              </div>
            )
          })} */}
          {recomendPro?(
            <ProductSlider data={recomendPro} />
          ):""}
          

        </div>
      </div>

    ):""}

    </>
  )
}

export default RecommendedProuct
