import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BASEURL } from '../utils/apiURL';
import axios from 'axios';

const initialState = {
    products: [],
    pro_Details: null,
    error: null,
    loading: false
    
}


export const fetchProductCategory = createAsyncThunk('product/category',
    async (cat_id) => {
        try {
            let response = await axios.get(`${BASEURL}products/${cat_id}`);
            console.log(response.data.data.data);
            return response.data.data.data;
        } catch (error) {
            return error.data
        }
    })

export const getProductDetails = createAsyncThunk('product/details',
    async (id) => {
        let response = await axios.get(`${BASEURL}product/${id}`)
        if (response.data.code === 200) {
            console.log(response.data);
            return response.data.data;
        } else if (response.data.code === 404) {
            // console.log(response.data.errors);
            // return response.data.errors;
            throw new Error(response.data);
        }
    })

export const getFilterdProducts = createAsyncThunk('product/filter',
    async (data) => {
        let response = await axios.post(`${BASEURL}get/filter/products`, data)
        console.log(response)
        if (response.data.code === 200) {
            console.log(response.data);
            return response.data.data.data;
        }
         else if (response.data.code === 404) {
            // console.log(response.data.errors);
            // return response.data.errors;
            throw new Error(response.data);
        }
    })


const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // featch Products by category id
            .addCase(fetchProductCategory.pending, (state) => {
                state.loading = true;
                state.products = [];
            })
            .addCase(fetchProductCategory.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(fetchProductCategory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // featch Filterd Products
            .addCase(getFilterdProducts.pending, (state) => {
                state.loading = true;
                // state.products = [];
            })
            .addCase(getFilterdProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })

            // Product Details
            .addCase(getProductDetails.pending, (state, action) => {
                state.loading = true;
                state.error = null;
                state.pro_Details = null
            })
            .addCase(getProductDetails.fulfilled, (state, action) => {
                state.loading = false;
                console.log(action.payload)
                state.pro_Details = action.payload;
                state.error = action.payload;
            })
            .addCase(getProductDetails.rejected, (state, action) => {
                state.loading = false;
                state.pro_Details = null;
                console.log(action.error);
                state.error = action.error; // Error message will be set here
            });

    }
});

export const { productAction } = productSlice.actions

export default productSlice.reducer